import { useDispatch, useSelector } from "react-redux"

import { withDynamicNamespaces } from "@components/hoc/withDynamicNamespaces"
import { setFlyoutContentURIAction, toggleFlyoutVisibilityAction } from "@redux/actions/application"
import { isFlyoutVisible } from "@redux/reducer/applicationStates"
import { NamespaceShortcut, useDynamicTranslation } from "@services/i18n"
import { MODULE_FAQ_AVAILABLE } from "config"

import ActionIcon from "../ActionIcon"
import { IconName } from "../Icon"

const usedNamespaces: NamespaceShortcut[] = ["faq-meta"]

interface IProps {
  className: string
  icon?: IconName
  faqKey?: string
}

/**
 * Provides a trigger-icon based on ActionIcon, to open/close the FAQFlyout.
 *
 * @returns FAQFlyoutTrigger: JSX.Element
 */
const FAQFlyoutTriggerIcon = (props: IProps): JSX.Element => {
  const t = useDynamicTranslation()
  const { className, icon = "question-mark-circle", faqKey = "" } = props
  const dispatch = useDispatch()
  const flyoutOpen = useSelector(isFlyoutVisible)

  return MODULE_FAQ_AVAILABLE && <ActionIcon
    onclick={() => {
      dispatch(setFlyoutContentURIAction(faqKey))
      dispatch(toggleFlyoutVisibilityAction())
    }}
    className={className}
    title={t("faq-meta", "flyout." + (flyoutOpen ? "close" : "open"))}
    icon={icon}
  />
}

export default withDynamicNamespaces<IProps>(FAQFlyoutTriggerIcon, usedNamespaces)