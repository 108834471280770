import get from "lodash/get"
import has from "lodash/has"
import useTranslation from "next-translate/useTranslation"
import * as Popper from 'popper.js'
import React, { ReactNode } from "react"
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap"

import { useToggleState } from "@services/hooks/useToggleState"
import { useTooltip } from "@services/tooltipHook"

interface IProps {
  "aria-label"?: string
  autoWrap?: boolean
  button: any
  className?: string
  light?: boolean
  title?: string
  tooltipPlacement?: Popper.Placement
  children: ReactNode
}

const DropdownComponent: React.FC<IProps> = (props: IProps) => {
  // per default all children are automatically wrapped in <DropdownItem>
  // this can be prevented by setting autoWrap to false, e.g. for cases when the Child is a React element
  // and thous will not be detected as empty, but still renders empty in some conditions, e.g. AuthElements
  // when the user has not the required role -> don't render empty Buttons, in this case include the
  // <DropdownItem> within the Authelements and all children of DropdownComponent must be wrapped manually
  const { autoWrap = true, tooltipPlacement = "left" } = props

  const wrappedChildren = autoWrap
    ? React.Children.map(props.children, (child, i) => {
      // fix react error: items in a list must have a key
      const key = has(child, "key") ? `key-${get(child, "key") as string}` : `index-${i}`

      return <>{child &&
        <DropdownItem key={key}>
          {child}
        </DropdownItem>
      }</>
    })
    : props.children

  const { t } = useTranslation("common")
  const { isTrue: isOpen, toggle } = useToggleState(false)
  const { tooltipTargetRef, ToolTip } = useTooltip(props.title, { placement: tooltipPlacement })


  return <>
    <Dropdown
      className={props.className}
      isOpen={isOpen}
      toggle={toggle}
      title={props.title}
    >
      <DropdownToggle aria-label={props["aria-label"] || t("default.dropdown")}>
        <span ref={tooltipTargetRef}>
          {props.button}
        </span>
      </DropdownToggle>
      <DropdownMenu className={props.light ? "dropdown-menu-light" : ""} right>
        {wrappedChildren}
      </DropdownMenu>
    </Dropdown>
    {ToolTip}
  </>
}

export default DropdownComponent
