import Icon from "@components/common/Icon"
import { SOCIAL_MEDIA_FACEBOOK, SOCIAL_MEDIA_INSTAGRAM } from "@customer-config"

/**
 * shows social media links if configured
 */
const SocialLinks: React.FC = () => <div className="social-icons">
  {SOCIAL_MEDIA_INSTAGRAM && <a aria-label="Instagram" href={SOCIAL_MEDIA_INSTAGRAM}><Icon name={"instagram"} size={25} /></a>}
  {SOCIAL_MEDIA_FACEBOOK && <a aria-label="Facebook" href={SOCIAL_MEDIA_FACEBOOK}><Icon name={"facebook"} size={25} /></a>}
</div>

export default SocialLinks