/*
 * this file is for definition of constants for customers
 * e.g. for defining project phases/timelines for switching functions on/off
 */
import Parser from "rss-parser"

import { EventFeed, EventItem } from "@components/common/events/RSSFeedToEventsView"
import { TimelinePhase, getLatestTimelinePhase, StringBasedStringArray } from "@services/util"


/** customer timeline */
const customerTimeline: TimelinePhase[] = [
  // { startDate: "2023-08-30", phase: 2 },
]


const currentPhase = getLatestTimelinePhase(customerTimeline)

// NOTE: put in the real env-var name!
export const CUSTOMER_PHASE: number = currentPhase?.phase
  ? currentPhase?.phase
  : parseInt(process.env.CUSTOMER_COTTBUS_PHASE, 10)




// #region Links zu Dokumenten in Challengekarte
/*
* Cottbus will Teilnahmebedingungen und Leitfaden zur Prämierung direkt in der Challenge-Karte anzeigen
*/
interface ExtraHtmlForChallenges {
  challengeId: number
  html: string
}


export const extraHTMLForChallenges: ExtraHtmlForChallenges[] = [
  {
    challengeId: 2,
    html: "<p><a href='/assets/cottbus/Leitfaden_zur_Praemierung.pdf' target='_new'>Leitfaden zur Prämierung</a><br /><a href='/assets/cottbus/Teilnahmebedingungen_Praemierung.pdf' target='_new'>Teilnahmebedingungen</a></p>"
  }
]
// #endregion

// #region events

// unused: seit der Umstellung des BTU Feeds wird diese Funktion nicht mehr genutzt (Stand: 27.11.2023)
export const processEventsFeed = (feed: EventFeed & Parser.Output<EventItem>): EventFeed & Parser.Output<EventItem> => {
  const updatedFeed = feed
  feed?.items.map((item, key) => {
    // der BTU RSS feed liefert in der description einen Daten-Mischmasch aus
    // startdate/enddate, eventLocation und eigentlicher description
    // daher wird die description zurechtgeschnitten und nur der Wert genommen, der
    // sich NACH der hineingemischten eventLocation befindet
    const eventLocationIndexInDescription = item.description.indexOf(item.eventLocation)
    const updatedDescription = eventLocationIndexInDescription > 0
      ? item.description.substring(eventLocationIndexInDescription + item.eventLocation.length)
      : item.description

    updatedFeed.items[key].description = updatedDescription
  })

  return updatedFeed
}

// #endregion events

// #region partnermarket

/**
 * Which style should the SupportRequestCard have?
 * By default: SUPPORT_REQUEST_CARD_PROJEKTFABRIK_STYLE
 * Every other value leads to a "simple version"
 */
// as string and not as enum or other imported variable,
// b/c: ReferenceError: Cannot access 'SUPPORT_REQUEST_CARD_PROJEKTFABRIK_STYLE' before initialization
// or
// ReferenceError: Cannot access <enum> before initialization: trying to read <enum>.<entry>
// @todo: https://futureprojects.atlassian.net/browse/FCP-1329
// eslint-disable-next-line @typescript-eslint/no-inferrable-types
export const SUPPORT_REQUEST_CARD_STYLE: string = "simple"

/**
 * order of the SupporterRole Filter in the Partnermarket
 * and when creating a SupportRequest
 */
// Cottbus wants the order to be "alphabetically" along the german translation
// except "Sonstiges" an last
export const SUPPORTER_ROLE_ORDER_AS_FILTER: string[] = [
  'feedback_provider',
  'supplier',
  'contributor',
  'ambassador', // Sachmittel
  'sponsor',
  'expert', // Sonstiges
]

/**
 * available feedback post types for the customer when creating a discussion/post
 * in the wanted order, based on FeedbackPostType
 *
 * set to null if all default types shall be used
 */
export const CUSTOMER_FEEDBACK_POST_TYPES: string[] = [
  'hint',
  'criticism',
  // 'troll', // no trolling for cottbus
  'question',
  'support'
]

/**
 * should the networking dashboard be used?
 *
 * NOTE: there must be at least an link/access to the SupportRequests, if the partnermarket is available
 * and USE_NETWORKING_DASHBOARD === false
 *
 * Cottbus does not want it to be used
 */
export const USE_NETWORKING_DASHBOARD = false



// #endregion partnermarket


/**
 * Alternative routes to be processed by the relevant pages to forward the user instead of
 * showing their content.
 *
 * NOTE: currently only implemented in pages Imprint + DataProtection
 *
 * @todo: https://futureprojects.atlassian.net/browse/FCP-1438
 */
export const ALTERNATIVE_ROUTES: StringBasedStringArray = {
  // [Routes.Imprint]: "https://sinn-sachsen.de/impressum.html#Impressum",
  // [Routes.DataProtection]: "https://sinn-sachsen.de/datenschutz.html#Datenschutz",
}

// #region social media links
// store URLs in those variables to configure the social media icons
/**
 * URL to a Facebook page of the current customer
 */
export const SOCIAL_MEDIA_FACEBOOK: string = null
/**
 * URL to a Instagram page of the current customer
 */
export const SOCIAL_MEDIA_INSTAGRAM: string = null
// #endregion social media links


/**
 * enables/disables the option that a processmanager is able to edit a project's profile
 */
export const ENABLE_MANAGER_EDIT_PROJECT_PROFILE = false
