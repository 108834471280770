import { useEffect, useState } from 'react'
import { Button } from 'reactstrap'

import { withDynamicNamespaces } from "@components/hoc/withDynamicNamespaces"
import { NamespaceShortcut, useDynamicTranslation } from "@services/i18n"

import Icon from "../Icon"
import { FAQEntryType, FAQHelper, FAQType } from "./FAQHelper"
import { useSetFAQKey } from './FAQHooks'
import FAQSearchInputField from './FAQSearchInputField'


const usedNamespaces: NamespaceShortcut[] = ["faq-meta"]

interface IProps {
  faqNode: FAQType
  sendFoundSearchResults(foundEntries: boolean): void
  sendEnteredInput(enteredValid: boolean): void
}

interface ISearchParams {
  pattern: string
}

/**
 * Shows the inputfield of the FAQ-system in order to enter a searchterm
 * and search-results.
 *
 * Is used by the FAQAreaLayer, used by the FAQMainComponent.
 *
 */
const FAQSearchView = ({ faqNode, sendFoundSearchResults, sendEnteredInput }: IProps): JSX.Element => {
  const t = useDynamicTranslation()
  const setFAQKey = useSetFAQKey()

  // entries: to hold all entries from the given faqnode
  const [entries, setEntries] = useState<{ [index: string]: FAQEntryType }>()
  // the input, entered by the user
  const [selectedInput, setSelectedInput] = useState<string>()
  // the search result with all questions and answers, matching the searchterm
  const [searchResult, setSearchResult] = useState<{ [index: string]: FAQEntryType } | undefined>()

  // if the user enters a term into the inputfield, the useState (selectedInput) will be updated
  const onSubmitSearch = (values: ISearchParams) => {
    setSelectedInput(values.pattern)
    sendEnteredInput(values.pattern !== "")
  }

  // resets input and searchResult
  const resetResultView = () => {
    setSelectedInput("")
    setSearchResult(undefined)
    sendEnteredInput(false)
  }

  // if the faqNode changes, update all entries
  useEffect(() => {
    setEntries(FAQHelper.getAllEntries(faqNode))
  }, [faqNode])

  // if the user has entered a new searchterm, update the searchresult
  useEffect(() => {
    setSearchResult(FAQHelper.searchInEntries(entries, selectedInput))
  }, [selectedInput])

  // if the searchresult has changed, check if at least one entry (with question and answer) has been found
  useEffect(() => {
    sendFoundSearchResults(searchResult !== undefined)
  }, [searchResult])

  // sub-component to show found entries
  const FoundEntries = (): JSX.Element => {
    return <div className="faq__search__results">
      {Object.keys(searchResult)
        .filter(key => entries[key].hasOwnProperty('question') && typeof entries[key].question === "string")
        .map(faqKey => <div
          className="faq__search__results__entry"
          key={"FAQSearch_" + faqKey}
        >
          <Icon name="document" size={14} />
          {/* a button: to be accessable by keyboard
          a link: wo look like a link
          a div: to drive the bottom border under every line of a multi-line text
          -> @todo: CSS rework @see also: FAQTopicCard
          */}
          <Button
            onClick={() => {
              const faqKeyParts: string[] = faqKey.split(".")
              setFAQKey(faqKeyParts[0] + "." + faqKeyParts[1] + "." + faqKeyParts[2])
              resetResultView()
            }}
          >
            <div>
              <a>{entries[faqKey].question}</a>
            </div>
          </Button>
        </div>
        )}
    </div>
  }

  return <>
    <FAQSearchInputField
      onSubmit={onSubmitSearch}
      searchParams={{ pattern: '' }}
      onCancel={resetResultView}
    />
    {entries && searchResult
      // if the user has entered a searchterm and entries are found, display them
      ? <FoundEntries />
      // if the user has entered a term, but nothing was found, display an error message
      : entries && selectedInput && searchResult === undefined && <p className="faq__search__no-entries-found">{t("faq-meta", "search.noEntriesFound")}</p>
    }
  </>
}

export default withDynamicNamespaces<IProps>(FAQSearchView, usedNamespaces)
