/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { useEffect, useState } from 'react'

import { withDynamicNamespaces } from '@components/hoc/withDynamicNamespaces'
import { NamespaceShortcut, useDynamicTranslation } from '@services/i18n'

import ActionIcon from '../ActionIcon'
import TranslatedHtml from '../TranslatedHtml'
import { FAQHelper, FAQLayer, FAQType, ISelectedEntry } from './FAQHelper'

const usedNamespaces: NamespaceShortcut[] = ["faq-meta"]

interface IProps {
  faqNode: FAQType
  faqKey: string | undefined
}

/**
 * Provides a single entry of the FAQ-System.
 * An entry is a question with an answer.
 *
 * The "EntryLayer" represents the third/last layer in the FAQ-System. It is used by the TopicLayer (upper layer).
 */
const FAQEntryLayer = (props: IProps): JSX.Element => {
  const t = useDynamicTranslation()

  // all entries of the selected topic, to be used to create the navigation (previous/next entry)
  const [entriesOfTopic, setEntriesOfTopic] = useState<ISelectedEntry[]>()
  // the entry, which is selected and shown currently
  const [selectedEntryIndex, setSelectedEntryIndex] = useState<number | undefined>(0)

  /**
   * the user should be able to navigate through all entries of the topic,
   * therefor the right and left button have to memorize, where to point at and be active
   * or if to be disabled
   */
  // stores the index of the entry, where the left button points at
  const [leftNavButtonIndex, setLeftNavButtonIndex] = useState<number | undefined>(undefined)
  // stores the index of the entry, where the right button points at
  const [rightNavButtonIndex, setRightNavButtonIndex] = useState<number | undefined>(undefined)

  useEffect(() => {
    // checks if the given FAQKey is defined
    if (props.faqKey) {
      // get all entries within the topic of the area
      setEntriesOfTopic(FAQHelper.getAllEntriesByFAQKey(props.faqNode, props.faqKey))
    } else {
      // if no FAQKey is defined, reset everything
      setEntriesOfTopic([])
      setSelectedEntryIndex(undefined)
      setRightNavButtonIndex(0)
      setLeftNavButtonIndex(0)
    }
  }, [props.faqKey, props.faqNode])

  useEffect(() => {
    if (entriesOfTopic) {
      const selectedEntry = entriesOfTopic.filter(entryKey => entryKey.key === FAQHelper.getKeyPart(props.faqKey, 3))
      if (selectedEntry.length === 1) {
        setSelectedEntryIndex(selectedEntry[0].index)
      }
    }
  }, [entriesOfTopic])

  // if the user selects a new entry: update the navigation-buttons
  useEffect(() => {
    if (selectedEntryIndex !== undefined && entriesOfTopic !== undefined) {
      // make sure, that the index is within the range of entries
      // the index of the buttons represents always the next entry to the left side or the right side
      // if the user has selected the first or the last entry, the index is undefined and for the user will the button be disabled
      const leftIndex = selectedEntryIndex === 0 ? undefined : selectedEntryIndex - 1
      const rightIndex = selectedEntryIndex === entriesOfTopic.length - 1 ? undefined : selectedEntryIndex + 1
      setLeftNavButtonIndex(leftIndex)
      setRightNavButtonIndex(rightIndex)
    }
  }, [selectedEntryIndex, entriesOfTopic])

  return FAQHelper.layerToBeShown(props.faqKey) === FAQLayer.EntryLayer && // give content back only, if the given faqKey is on EntryLayer-level
    <div className="faq__entry-layer">
      {selectedEntryIndex !== undefined && entriesOfTopic && // if there are entries and one of it is selected, show question & answer
        <>
          <div className="faq__entry-layer__content">
            <h2><TranslatedHtml content={entriesOfTopic[selectedEntryIndex].question} /></h2>
            <TranslatedHtml content={entriesOfTopic[selectedEntryIndex].answer} />
          </div>
        </>
      }
      { // show navigation only, if there is more than one entry
        entriesOfTopic && entriesOfTopic.length > 1 &&
        <div className='faq__entry-layer__navigation'>
          { /*
          navigation-button: left: using ActionIcon for Tooltip ability
          */}
          <ActionIcon
            onclick={leftNavButtonIndex !== undefined ? () => setSelectedEntryIndex(leftNavButtonIndex) : null}
            title={leftNavButtonIndex !== undefined ? t("faq-meta", "faq") + ": " + entriesOfTopic[leftNavButtonIndex].question : ""}
            icon={"arrow-left"}
            className="page-navigation-btn"
            disabled={leftNavButtonIndex === undefined}
            // key is necessary to avoid tooltip doubling effects (@todo: investigate phenomenon by clicking left/right buttons after each other)
            key={leftNavButtonIndex !== undefined ? entriesOfTopic[leftNavButtonIndex].question + "_left_navigation_button" : "left_navigation_button"}
          />
          {/* navigation-"circles" for direct access to every entry */}
          <div className='faq__entry-layer__navigation__circles'>
            {entriesOfTopic.map((entry) => <ActionIcon
              onclick={() => setSelectedEntryIndex(entry.index)}
              title={t("faq-meta", "faq") + ": " + entry.question}
              icon={"circle"}
              className={selectedEntryIndex === entry.index ? "circle-button-active actionicon" : "actionicon"}
              key={entry.key}
            />)}
          </div>
          {/* navigation-button: right: using ActionIcon for Tooltip ability */}
          <ActionIcon
            onclick={rightNavButtonIndex !== undefined ? () => setSelectedEntryIndex(rightNavButtonIndex) : null}
            title={rightNavButtonIndex !== undefined ? t("faq-meta", "faq") + ": " + entriesOfTopic[rightNavButtonIndex].question : ""}
            icon={"arrow-right"}
            className="page-navigation-btn"
            disabled={rightNavButtonIndex === undefined}
            // key is necessary to avoid tooltip doubling effects (@todo: investigate phenomenon by clicking left/right buttons after each other)
            key={rightNavButtonIndex !== undefined ? entriesOfTopic[rightNavButtonIndex].question + "_right_navigation_button" : "right_navigation_button"}
          />
        </div>
      }
    </div >
}

export default withDynamicNamespaces<IProps>(FAQEntryLayer, usedNamespaces)