import classNames from "classnames"
import Head from "next/head"
import { ReactNode } from "react"
import { connect, ConnectedProps } from "react-redux"
import { AnyAction, Dispatch } from "redux"

import Footer from "@components/layout/Footer"
import Header from "@components/layout/Header"
import PageBody from "@components/layout/PageBody"
import { AppState } from "@redux/reducer"
import { selectRoles } from "@redux/reducer/auth"
import { logoutAction } from "@redux/usecases/userAccount"
import { platformIsInDevEnvironment } from "@services/util"

// variables from the SCSS that are explicitly exported there
import cssVariables from "./../../styles/constants.module.scss"
import FAQFlyout from "./common/faq/FAQFlyout"
import ScrollToTopButton from "./common/ScrollToTopButton"


const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  doLogout: () => dispatch(logoutAction()),
})

const mapStateToProps = (state: AppState) => ({
  roles: selectRoles(state),
  authTTL: state.platform.authTTL,
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type Props = ConnectedProps<typeof connector> & {
  children: ReactNode
  isFrontPage?: boolean
  isMarketplace?: boolean
  pageTitle: string
  metaData?: ReactNode
}

export const SCROLLABLE_MAIN_PAGE = "scrollable-page"

/**
 * This component provides a basic layout for every page
 * and should therefor be used in every page.
 *
 * It includes
 * - html-header
 * - page-header
 * - body with component children
 * - footer
 *
 * BaseLayout is used by every page at /pages to let it look the same way
 */
const BaseLayout: React.FC<Props> = (props: Props) => {

  const className = classNames(
    "common-page",
    { "frontpage": props.isFrontPage },
    { "subpage": !props.isFrontPage }
  )
  const primaryColor = cssVariables.primaryColor

  // #region favicon preparation
  // Favicon is set in the CSS files and may be overwritten for customer styles.
  // A favicon url is preferred to a favicon SVG code.
  // Favicon-code in CSS is wrapped into "", but these characters are transferred to the react code base
  // so they must be removed.
  let favicon = cssVariables.faviconCode?.substring(1, cssVariables.faviconCode.length - 1)

  let faviconType = "image/svg+xml"
  // if a faviconUrl is given from the CSS file it is preferred to the favicon SVG code and overwrites prior data
  if (cssVariables.faviconUrl) {
    // remove wrapping ""
    favicon = cssVariables.faviconUrl.substring(1, cssVariables.faviconUrl.length - 1)
    faviconType = "image/png"
  }
  // #endregion favicon preparation

  return <>
    <Head>
      <title>{props.pageTitle}</title>
      <meta charSet="utf-8" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <meta name="robots" content="index, follow" />

      {/* custom favicon, just using icon without size, described in: https://evilmartians.com/chronicles/how-to-favicon-in-2021-six-files-that-fit-most-needs */}
      <link rel="icon" type={faviconType} href={favicon} />
      {/* if no custom favicon is declared, this is the neutral fall-back
      (must be located in root, we need sizes="32x32" in order to fix a Chrome bug where it chooses an ICO file over an SVG.) */}
      <link rel="icon" href="/favicon.ico" sizes="32x32"></link>
      {/* neutral favicon Safari touch-icon (must be located in root) */}
      <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
      {/* neutral favicon for Safari pinned tabs, requires one color only on transparent background */}
      <link rel="mask-icon" href="/mask-icon.svg" color={primaryColor} />
      {/* favicon Android Chrome (must be located in root) */}
      <link rel="manifest" href="manifest.json" />
      {/* favicon in MS-tile (must be located in root) */}
      <meta name="msapplication-TileColor" content="#2d89ef"></meta>
      <meta name="theme-color" content={cssVariables.white} />
      {/* title for link on desktop */}
      <meta name="apple-mobile-web-app-title" content="Projekt-App"></meta>
      <meta name="application-name" content="Projekt-App"></meta>
      {/* Data Protection, @see: https://webbkoll.dataskydd.net/en/results?url=http://dev.projektfabrik.net/ */}
      <meta name="referrer" content="no-referrer" />
      {/* the following definition does not work since it does not allow to load content from other sources than itself.
      @todo: how to configure all (!) allowed content-elements, including embedded videos + video conferencing?
      @see: https://www.stackhawk.com/blog/react-content-security-policy-guide-what-it-is-and-how-to-enable-it/
      <meta httpEquiv="Content-Security-Policy" content="script-src 'self'" />
      */}

      {/* social media card default definitions */}
      <meta name="og:title" content={props.pageTitle} />
      <meta name="og:locale" content="de_DE" />
      <meta property="og:type" content="website" />
      <meta name="twitter:title" content={props.pageTitle} />
      {/* additional meta data, especially for social media card details */}
      {props.metaData}

      {/* <!-- Matomo --> */}
      {// do not use matomo in local dev environment, b/c of https://futureprojects.atlassian.net/browse/FCP-1298
        !platformIsInDevEnvironment() &&
        <script src="/assets/cottbus/matomo.js" async></script>
      }

      {/* <!-- End Matomo Code --> */}
    </Head>

    <div className="container-with-content-and-flyout">
      {
        // the id is important for the infinite scrollcomponent to identify, which is the scroll target
      }
      <div id={SCROLLABLE_MAIN_PAGE} className={className}>
        <Header
          authTTL={props.authTTL}
          doLogout={props.doLogout}
          isFrontPage={props.isFrontPage}
          isMarketplace={props.isMarketplace}
          pageTitle={props.pageTitle}
          roles={props.roles}
        />
        <PageBody>
          {props.children}
        </PageBody>
        <Footer
          isFrontPage={props.isFrontPage}
          roles={props.roles}
        />
        {/*
        * Cottbus: Matomo tracking picture
        * "img" behalten und nicht auf Image umstellen, um sicherzugehen, dass es geladen wird
        */}
        <img src="https://analytics.cottbus-digital.de/matomo.php?idsite=2&amp;rec=1" style={{ border: 0, width: 0, height: 0 }} alt="" />
        {/*
          * NOTE: ScrollToTopButton has fixed-position (and is visible after scrolling to a scroll treshold), i.e.
          * its visual position is relative to the rest of the page and changes when scrolling.
          * However, we can't reflect this dynamic behaviour in tabulator navigation, but must instead decide
          * its "navigational" position at design-time: before/after header/footer/children.
          * Placing it after the footer leads to it being the last element in tab-navigation, or the first one
          * in reverse (Shift+Tab).
          */}
        <ScrollToTopButton />
      </div>
      {
        /**
         * @todo phillipp: replace with Flyout and find a way to insert the FAQMainComponent
         * Why?
         * The idea is to insert any content into the flyout. Therefor the trigger has to know, which content should be displayed on the flyout.
         * Current general design: Any possible content should be a child of the flyout component.
         *
         * Currently I discovered two possibilities:
         *
         * 1) Identify the children of the Flyout through theirs names. Each child has within the type their name. Is it always available?
         *
         * Example Code:
         *
          const findComponentToDisplayInFlyout = (): JSX.Element => {
            let result: JSX.Element = null
              Children.forEach(children, child => {
                 if (nameOfComponent === child.type.name) {
                   result = child
                   return
                  }
              })
            return result
          }
         *
         * 2) Define a child component, which has name property for identification und a component property, which represents the content.
         * With PropTyps you can make sure, that these properties are requiered of each children within the flyout.
         *
         * Example Code for PropTyp
         *
          Flyout.propTypes = {
            children: PropTypes.shape({
              props: PropTypes.shape({
                  name: PropTypes.string.isRequired,
                  component: PropTypes.element.isRequired
              })
            }).isRequired,
          }
         * Example Code for the additional child component:
         *
          type ChildProps = {
            name: string
            component: JSX.Element
          }
          export const FlyoutChild = ({ name, component }: ChildProps): JSX.Element => {
              const contentURI = useSelector(selectFlyoutContentURI)
              const parts = contentURI.split("://")
              const nameOfComponent = parts[0]
              if (nameOfComponent === name) {
                  return component
              }
              return null
          }
         */
      }
      <FAQFlyout />
    </div>
  </>
}

export default connector(BaseLayout)


