import { UserRole } from "@api/schema"
import AuthElement from "@components/common/AuthElement"
import DropdownComponent from "@components/common/DropdownComponent"
import FAQFlyoutTriggerIcon from "@components/common/faq/FAQFlyoutTriggerIcon"
import Icon from "@components/common/Icon"
import { withDynamicNamespaces } from "@components/hoc/withDynamicNamespaces"
import { NamespaceShortcut, useDynamicTranslation } from "@services/i18n"
import { Routes } from "@services/routes"
import { useTooltip } from "@services/tooltipHook"
import { humanTime } from "@services/util"
import { MODULE_LOGOUT_TIMER_AVAILABLE, MODULE_PARTNER_MARKET_AVAILABLE } from "config"

import NavIcon from "./NavIcon"
import NavigationDropDownItem from "./NavigationDropDownItem"

const usedNamespaces: NamespaceShortcut[] = ["base-layout"]

interface IProps {
  // @todo diese ganzen Properties nicht aus den Elternkomponenten holen sondern
  // aus mapStateToProps damit die Elternkomponenten nicht neu gerendert werden weil sich
  // props ändern die sie nicht brauchen sondern nur durchreichen: https://i.imgur.com/xpwcEH3.png
  authTTL: number
  doLogout: () => void
  isFrontPage?: boolean
  roles: string[]
}

/**
 * provides the navigation icons on every page of the application
 */
const HeaderIconNavigation: React.FC<IProps> = (props: IProps) => {
  const t = useDynamicTranslation()

  const { tooltipTargetRef, ToolTip } = useTooltip(t("base-layout", "timeTillLogout"))

  return <nav role="navigation" className="icon-navigation">
    <>
      <NavIcon href="/so-gehts" icon="customer-icon-guide" title="So funktioniert das IdeenLAB" />
      <NavIcon href="/ueber" icon="customer-icon-info" title="Über das Projekt IdeenLAB" />
      <NavIcon href="/#veranstaltungen" icon="calendar" title="Veranstaltungen" />
    </>

    <AuthElement roles={props.roles} requiredRole={[UserRole.ProcessManager]}>
      <NavIcon href={Routes.AdminDashboard} icon="monitor" title={t("base-layout", "adminDashboard")} />
    </AuthElement>

    <NavIcon href={Routes.Marketplace} icon="market" title={t("base-layout", "marketplace")} />
    {MODULE_PARTNER_MARKET_AVAILABLE && <NavIcon href={Routes.PartnerMarket} icon="partner" title={t("base-layout", "partnerMarket")} />}
    {
      // Cottbus will Prozessübersicht nicht sichtbar haben.
      // <NavIcon href={Routes.ProcessOverview} icon="info-bubble" title={t("base-layout", "processOverview")} />
    }


    <DropdownComponent
      autoWrap={false}
      button={
        <span className="actionicon"><Icon name="user" size={24} /></span>
      }
      light={props.isFrontPage}
      title={t("base-layout", "user")}
    >
      <NavigationDropDownItem
        roles={props.roles}
        requiredRole={UserRole.User}
        label={t("base-layout", "userDashboard")}
        href={Routes.UserDashboard}
      />

      <NavigationDropDownItem
        roles={props.roles}
        requiredRole={UserRole.User}
        label={t("base-layout", "myProjects")}
        href={Routes.MyProjects}
      />
      <NavigationDropDownItem
        roles={props.roles}
        requiredRole={UserRole.User}
        label={t("base-layout", "logout")}
        onClick={props.doLogout}
      />
      <NavigationDropDownItem
        roles={props.roles}
        requiredRole={UserRole.Guest}
        href={Routes.Login}
        label={t("base-layout", "login")}
      />
    </DropdownComponent>

    {MODULE_LOGOUT_TIMER_AVAILABLE && props.authTTL > 0 && <AuthElement roles={props.roles} requiredRole={UserRole.User}>
      <div ref={tooltipTargetRef}>{humanTime(props.authTTL)}</div>
      {ToolTip}
    </AuthElement>
    }

    <FAQFlyoutTriggerIcon className="actionicon faq-icon" />
  </nav >
}

export default withDynamicNamespaces<IProps>(HeaderIconNavigation, usedNamespaces)
