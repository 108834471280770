import { useDispatch, useSelector } from 'react-redux'

import ActionIcon from '@components/common/ActionIcon'
import { withDynamicNamespaces } from '@components/hoc/withDynamicNamespaces'
import { toggleFlyoutVisibilityAction } from '@redux/actions/application'
import { isFlyoutVisible } from '@redux/reducer/applicationStates'
import { NamespaceShortcut, useDynamicTranslation } from '@services/i18n'

const usedNamespaces: NamespaceShortcut[] = ["base-layout"]

type IProps = {
  children: JSX.Element
}

/**
 * The Flyout represents a sidebar with a close-icon and some content.
 * In desktop size it's a sidebar, on mobile views it's displayed in full width
 * Content of the Flyout is provided as children (ReactNode), e.g. the FAQMainComponent to visualize the FAQ.
 *
 * The Flyout use the redux-store to handle the Flyouts visibility.
 */
const Flyout = ({ children }: IProps): JSX.Element => {
  const t = useDynamicTranslation()
  const isVisible = useSelector(isFlyoutVisible)
  const dispatch = useDispatch()

  return isVisible &&
    <div className="flyout-container">
      <div className='flyout-fixed'>
        <>
          <ActionIcon
            onclick={() => dispatch(toggleFlyoutVisibilityAction())}
            title={t("base-layout", "flyout.close")}
            icon={"cancel"}
            className="flyout-close-icon actionicon"
          />
          {children}
        </>
      </div>
    </div>
}

export default withDynamicNamespaces<IProps>(Flyout, usedNamespaces)